import sessionService from '../services/sessionStorageService'

export const SESSION_STORAGE_KEY = 'autocompleteToken'

const getSessionTokenGuidFromPropertyWithUnstableNameOn = (sessionToken) =>
  sessionToken[Object.keys(sessionToken)[0]]

const setSessionTokenGuidOnPropertyWithUnstableNameOn = (
  sessionToken,
  sessionTokenGuid
) => {
  sessionToken[Object.keys(sessionToken)[0]] = sessionTokenGuid
}

export const createSessionTokenFrom = (googleService) => {
  const sessionTokenGuid = sessionService.getItem(SESSION_STORAGE_KEY)

  const sessionToken = new googleService.maps.places.AutocompleteSessionToken()

  if (sessionTokenGuid === null) {
    const sessionTokenGuid = getSessionTokenGuidFromPropertyWithUnstableNameOn(
      sessionToken
    )
    sessionService.setItem(SESSION_STORAGE_KEY, sessionTokenGuid)
  } else {
    setSessionTokenGuidOnPropertyWithUnstableNameOn(
      sessionToken,
      sessionTokenGuid
    )
  }

  return sessionToken
}

export const invalidateSessionToken = () => {
  sessionService.removeItem(SESSION_STORAGE_KEY)
}
