import axios from 'axios'
import config from '../config'

export const get = async (url) => {
  const response = await axios
    .get(`${config.apiBaseUrl}${url}`)
    .catch((err) => {
      return { error: true }
    })
  return response.error ? response : response.data
}

export const getConsistent = async (url) => {
  try {
    const response = await axios.get(url)

    return {
      success: true,
      data: response.data,
    }
  } catch (error) {
    return { success: false }
  }
}

export const getSearch = async (url) => {
  const searchResult = await getConsistent(`${config.searchBaseUrl}${url}`)

  return searchResult
}

export const getStats = async (url) => {
  const statsResult = await getConsistent(`${config.statsBaseUrl}${url}`)

  return statsResult
}

export const getApi = async (url) => getConsistent(`${config.apiBaseUrl}${url}`)

export const performApiGet = async (url, getConfig) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
  })

  return axiosInstance.get(url, getConfig)
}

export const performApiPost = async (url, data, postConfig) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
  })

  return axiosInstance.post(url, data, postConfig)
}

export const performApiPut = async (url, data) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
  })

  return axiosInstance.put(url, data)
}
