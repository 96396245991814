export const billTypes = {
  gas: 'gas',
  electricity: 'electricity',
  water: 'water',
  internet: 'internet',
  none: 'none',
}

export const allBillsSelected = [
  billTypes.gas,
  billTypes.electricity,
  billTypes.water,
  billTypes.internet,
]

export const allBillsDeselected = []
