import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ClearIcon from './clear-icon.svg'

import styles from './styles.module.scss'

const ClearButton = ({ className, ...props }) => {
  return (
    <button
      type="button"
      aria-label="Clear"
      {...props}
      className={classNames(styles.clearButton, className)}
    >
      <span className={styles.clearButtonInner}>
        <ClearIcon className={styles.clearIcon} />
      </span>
    </button>
  )
}

ClearButton.propTypes = {
  className: PropTypes.string,
}

export default ClearButton
