import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import config from '../config';
import { useAutocompleteContext, AutocompleteLanguage } from '../contexts/autocompleteContext';
import removeAttributeFromObject from '../utils/removeAttributeFromObject';
const useGoogleAutocompleteLoader = ({
  language: autocompleteLanguage,
  onError
}: {
  language?: AutocompleteLanguage;
  onError?: () => void;
}) => {
  const {
    language,
    setLanguage
  } = useAutocompleteContext();
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const removePreviousLoaderInstance = () => {
    // @ts-ignore
    Loader.instance?.deleteScript();
    // @ts-ignore
    Loader.instance?.reset();
    // @ts-ignore
    Loader.instance = undefined;
    // A hacky way to remove existing Loader.instance - unfortunately the npm package does not expose any methods to achieve this
  };
  useEffect(() => {
    const languageHasChanged = autocompleteLanguage !== language;
    // @ts-ignore
    const previousLoaderInstanceExists = Loader.instance;
    if (languageHasChanged) {
      setLanguage(autocompleteLanguage);
    }
    if (languageHasChanged && previousLoaderInstanceExists) {
      removePreviousLoaderInstance();
      removeAttributeFromObject(window, 'google');
    }
    const loader = new Loader({
      apiKey: config.googleMapsJsApiKey,
      libraries: ['places'],
      language: autocompleteLanguage
    });
    loader.importLibrary('places').then(() => {
      setGoogleLoaded(true);
    }).catch(() => {
      if (onError) onError();
    });
  }, []);
  return {
    googleLoaded
  };
};
export default useGoogleAutocompleteLoader;