const removeAttributeFromObject = <O extends object, A extends keyof O>(
  object: O,
  attribute: A
): Omit<O, A> => {
  if (attribute in object) {
    delete object[attribute]
  }

  return object
}

export default removeAttributeFromObject
