const setItem = (id, value) => {
  let sessionValue = value

  if (typeof sessionValue === 'object') {
    sessionValue = JSON.stringify(sessionValue)
  }

  sessionStorage.setItem(id, sessionValue)
}

const getItem = (id) => {
  const value = sessionStorage.getItem(id)

  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

const removeItem = (id) => {
  sessionStorage.removeItem(id)
}

const clear = () => {
  sessionStorage.clear()
}

export default {
  setItem,
  getItem,
  removeItem,
  clear,
}
