const NodeCache = require('node-cache')

let nodeCache

if (!global.nodeCache) {
  global.nodeCache = new NodeCache()
}

nodeCache = global.nodeCache

module.exports = { cache: nodeCache }
