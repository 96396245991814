import { cache } from '../cache'
import canUseDom from '../utils/canUseDom'

export const tenMinutesInSeconds = 600

const createCacheTimestamps = (durationInSeconds) => {
  const now = new Date()
  const start = now.toISOString()
  const durationInMilliseconds = durationInSeconds * 1000

  now.setTime(now.getTime() + durationInMilliseconds)
  const expiry = now.toISOString()

  return {
    start,
    expiry,
  }
}

export const getOrAdd = async (
  key,
  callback,
  duration = tenMinutesInSeconds
) => {
  let cacheValue = cache.get(key)

  if (cacheValue !== undefined) {
    return Promise.resolve(cacheValue)
  }

  cacheValue = await callback()

  if (cacheValue === undefined) return

  cache.set(key, cacheValue, duration)

  const timeStamps = createCacheTimestamps(duration)

  if (!canUseDom) {
    console.log(
      `${timeStamps.start}: ${key} not found in cache, now set until ${timeStamps.expiry}`
    )
  }

  return cacheValue
}

export const add = async (key, callback, duration = tenMinutesInSeconds) => {
  const cacheValue = await callback()

  if (cacheValue === undefined) return

  cache.set(key, cacheValue, duration)

  const timeStamps = createCacheTimestamps(duration)

  if (!canUseDom) {
    console.log(
      `${timeStamps.start}: ${key} now set until ${timeStamps.expiry}`
    )
  }

  return cacheValue
}
