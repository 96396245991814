import {
  createSessionTokenFrom,
  invalidateSessionToken,
} from '../models/googleApiSessionTokenFactory'
import { isLatitude } from '../contexts/countryContext'

import { radius } from '../constants/radius'

// Fix for variable Lat and Lng property names in places details service api, determine if the property is lat or lng by its number

export const getNeCornerLatAndLngFromGoogleViewport = (
  viewport,
  countryCode
) => {
  let neCornerLat
  let neCornerLng

  const viewportPropertyNames = Object.keys(viewport)

  const firstViewportProperty = viewport[viewportPropertyNames[0]]
  const secondViewportProperty = viewport[viewportPropertyNames[1]]

  const firstViewportPropertyPropertyNames = Object.keys(firstViewportProperty)
  const secondViewportPropertyPropertyNames = Object.keys(
    secondViewportProperty
  )

  const firstViewportPropertyNeValue =
    firstViewportProperty[firstViewportPropertyPropertyNames[1]]
  const secondViewportPropertyNeValue =
    secondViewportProperty[secondViewportPropertyPropertyNames[1]]

  if (isLatitude(firstViewportPropertyNeValue, countryCode)) {
    neCornerLat = firstViewportPropertyNeValue
    neCornerLng = secondViewportPropertyNeValue
  } else {
    neCornerLat = secondViewportPropertyNeValue
    neCornerLng = firstViewportPropertyNeValue
  }

  return {
    neCornerLat,
    neCornerLng,
  }
}

export const getRadiusOfGooglePlaceInMiles = ({
  neCornerLat,
  neCornerLng,
  centreLat,
  centreLng,
}) => {
  let minimumRadiusForSearchInMiles = radius.default

  const londonNeCornerLat = 51.6857538
  const londonNeCornerLng = 0.3096771

  const londonSwCornerLat = 51.2885471
  const londonSwCornerLng = -0.5342102

  const pointOfInterestIsLocatedWithinLondon =
    centreLat <= londonNeCornerLat &&
    centreLat >= londonSwCornerLat &&
    centreLng <= londonNeCornerLng &&
    centreLng >= londonSwCornerLng

  const failedToGetViewboxCoordinates =
    typeof neCornerLat === 'undefined' || typeof neCornerLng === 'undefined'

  if (failedToGetViewboxCoordinates) {
    return pointOfInterestIsLocatedWithinLondon
      ? radius.london
      : minimumRadiusForSearchInMiles
  }

  const radiusOfEarthInKm = 6371
  const degreesInRadian = 57.2958

  const neCornerLatInRadians = neCornerLat / degreesInRadian
  const neCornerLngInRadians = neCornerLng / degreesInRadian
  const centreLatInRadians = centreLat / degreesInRadian
  const centreLngInRadians = centreLng / degreesInRadian

  const ratioOfAreaRadiusVsAreaRadiusWithGoogleViewboxPadding = 0.7

  if (pointOfInterestIsLocatedWithinLondon) {
    minimumRadiusForSearchInMiles = radius.london
  }

  const radiusInKm =
    radiusOfEarthInKm *
    Math.acos(
      Math.sin(centreLatInRadians) * Math.sin(neCornerLatInRadians) +
        Math.cos(centreLatInRadians) *
          Math.cos(neCornerLatInRadians) *
          Math.cos(neCornerLngInRadians - centreLngInRadians)
    )

  const radiusInMiles = radiusInKm / 1.60934
  const adjustedRadiusInMiles =
    radiusInMiles * ratioOfAreaRadiusVsAreaRadiusWithGoogleViewboxPadding

  if (minimumRadiusForSearchInMiles > adjustedRadiusInMiles)
    return minimumRadiusForSearchInMiles

  return Number(adjustedRadiusInMiles.toFixed(4))
}

export const getCoordinatesFromGooglePlaceId = async (
  countryCode,
  googlePlaceId,
  googleService = google
) => {
  const service = new googleService.maps.places.PlacesService(
    document.createElement('div')
  )

  const requestParams = {
    placeId: googlePlaceId,
    fields: ['geometry'],
    sessionToken: createSessionTokenFrom(googleService),
  }

  return new Promise((resolve) => {
    service.getDetails(requestParams, (placeResult, placesServiceStatus) => {
      if (placesServiceStatus !== 'OK') resolve({ success: false })
      else {
        const { location, viewport } = placeResult.geometry

        const centreLat = location.lat()
        const centreLng = location.lng()

        const { neCornerLat, neCornerLng } =
          getNeCornerLatAndLngFromGoogleViewport(viewport, countryCode)

        invalidateSessionToken()

        resolve({
          success: true,
          latitude: centreLat,
          longitude: centreLng,
          radius: getRadiusOfGooglePlaceInMiles({
            neCornerLat,
            neCornerLng,
            centreLat,
            centreLng,
          }),
        })
      }
    })
  })
}
