import { createSessionTokenFrom } from '../models/googleApiSessionTokenFactory'
import {
  autocompleteCountryNameForCountryCode,
  getSearchAutoCompleteCountryRestrictions,
  getGoogleApiTermsToRemove,
  getSearchAutocompleteLanguageFor,
} from '../contexts/countryContext'

const countryFromTerms = (googleTerms) => {
  const terms = googleTerms.map((term) => term.value)

  if (terms.length === 0) return 'UK'

  return terms[terms.length - 1]
}

const nameFromTerms = (googleTerms, countryCode) => {
  const terms = googleTerms.map((term) => term.value)

  if (terms.length === 1) return terms[0]

  return terms
    .slice(0, terms.length - getGoogleApiTermsToRemove(countryCode))
    .join(', ')
}

const labelFromTerms = (googleTerms, countryCode) => {
  const terms = googleTerms.map((term) => term.value)
  const mainText = terms[0]
  const secondaryText = terms
    .slice(1, terms.length - getGoogleApiTermsToRemove(countryCode))
    .join(', ')

  return {
    mainText,
    secondaryText,
  }
}

const mapTypes = (googleTypes) => {
  if (googleTypes.includes('locality')) {
    return 'city'
  }
  if (googleTypes.includes('university')) {
    return 'university'
  }
  if (googleTypes.includes('route')) {
    return 'street'
  }
  if (googleTypes.includes('sublocality')) {
    return 'area'
  }

  return 'location'
}

export const getAutocompleteSuggestions = async (
  countryCode,
  searchTerm,
  googleService = google
) => {
  const service = new googleService.maps.places.AutocompleteService()

  const requestParams = {
    input: searchTerm,
    fields: ['terms', 'types', 'place_id'],
    componentRestrictions: {
      country: getSearchAutoCompleteCountryRestrictions(countryCode),
    },
    language: getSearchAutocompleteLanguageFor(countryCode),
    sessionToken: createSessionTokenFrom(googleService),
  }

  return new Promise((resolve) => {
    service.getPlacePredictions(
      requestParams,
      (placesAutocompletePredictions, placesServiceStatus) => {
        if (placesServiceStatus === 'ZERO_RESULTS') {
          resolve({
            success: true,
            suggestions: [],
            country: autocompleteCountryNameForCountryCode(countryCode),
          })
        }

        if (placesServiceStatus !== 'OK') {
          resolve({
            success: false,
          })
        }

        if (placesServiceStatus === 'OK') {
          resolve({
            success: true,
            suggestions: placesAutocompletePredictions.map((prediction) => {
              return {
                name: nameFromTerms(prediction.terms, countryCode),
                label: labelFromTerms(prediction.terms, countryCode),
                type: mapTypes(prediction.types),
                placeId: prediction.place_id,
                country: countryFromTerms(prediction.terms),
              }
            }),
            country: countryFromTerms(placesAutocompletePredictions[0].terms),
          })
        }
      }
    )
  })
}
