import React, { useState, useEffect, useRef, forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useCombobox } from 'downshift'

import throttle from '../../../utils/throttle'
import getMatchedAndNotMatchedSubstrings from './getMatchedAndNotMatchedSubstrings'
import useGoogleAutocompleteLoader from '../../../hooks/useGoogleAutocompleteLoader'

import UniversityIcon from '../../../svgs/icons/university-icon.svg'
import MapPinIcon from '../../../svgs/icons/map-pin-icon.svg'
import CityIcon from '../../../svgs/icons/city-icon.svg'

import styles from './styles.module.scss'

const icons = {
  city: <CityIcon className={styles.cityIcon} data-testid="city-icon" />,
  university: (
    <UniversityIcon
      className={styles.universityIcon}
      data-testid="university-icon"
    />
  ),
  location: (
    <MapPinIcon className={styles.mapPinIcon} data-testid="location-icon" />
  ),
  area: (
    <MapPinIcon className={styles.mapPinIcon} data-testid="location-icon" />
  ),
  street: (
    <MapPinIcon className={styles.mapPinIcon} data-testid="location-icon" />
  ),
}

const renderSuggestedItem = (label, searchTerm) => {
  const {
    matchedSubstring,
    notMatchedSubstringAtTheBeginning,
    notMatchedSubstringAtTheEnd,
  } = getMatchedAndNotMatchedSubstrings(label.mainText, searchTerm)

  return (
    <span className={styles.suggestedItemText}>
      <span className={styles.suggestedItemMainText}>
        {notMatchedSubstringAtTheBeginning && (
          <span>{notMatchedSubstringAtTheBeginning}</span>
        )}
        <span className={styles.suggestedItemMatchedText}>
          {matchedSubstring}
        </span>
        {notMatchedSubstringAtTheEnd && (
          <span>{notMatchedSubstringAtTheEnd}</span>
        )}
      </span>
      {label.secondaryText && (
        <span className={styles.suggestedItemSecondaryText}>
          {' '}
          {label.secondaryText}
        </span>
      )}
    </span>
  )
}

const setOptionsFromApiSuggestions = (
  setOptions,
  response,
  setNoMatches,
  setCountry
) => {
  const suggestionsFound = response.success && response.suggestions.length > 0

  const noMatches = response.success && response.suggestions.length === 0

  if (suggestionsFound) {
    setNoMatches(false)
    setOptions(response.suggestions)
  }

  if (noMatches) {
    setNoMatches(true)
    setOptions([])
  }

  if (setCountry) {
    setCountry(response.country)
  }
}

const getSuggestionsFromApi = throttle(
  async (
    searchTerm,
    setOptions,
    autocompleteApi,
    setNoMatches,
    setError,
    setCountry,
    countryCode
  ) => {
    const response = await autocompleteApi(countryCode, searchTerm)

    if (response.success) {
      setOptionsFromApiSuggestions(
        setOptions,
        response,
        setNoMatches,
        setCountry
      )
    } else setError(true)
  },
  1000
)

const LocationAutocompleteField = forwardRef(
  (
    {
      className,
      id,
      name,
      initialValue,
      handleOnChange,
      handleOnSelect,
      handleOnSubmit,
      autocompleteApi,
      countryCodeForAutocomplete,
      autoFocus,
      showGeoCoordinatesError,
      clearGeoCoordinatesError,
      setCountry,
      renderInputWrapper,
      renderInput,
      renderClearButton,
      renderLabel,
      renderMenuContainer,
    },
    ref
  ) => {
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(initialValue)
    const [noMatches, setNoMatches] = useState(false)
    const [showEmptyState, setShowEmptyState] = useState(false)
    const [error, setError] = useState(false)

    const inputRef = useRef(null)

    const { googleLoaded } = useGoogleAutocompleteLoader({
      language: 'en-GB',
      onError: () => {
        setError(true)
      },
    })

    const updateAutocompleteOptions = (inputValue) => {
      if (!inputValue || inputValue.length < 3) {
        setOptions([])
        return
      }

      if (!googleLoaded) return

      getSuggestionsFromApi(
        inputValue,
        setOptions,
        autocompleteApi,
        setNoMatches,
        setError,
        setCountry,
        countryCodeForAutocomplete
      )
    }

    useEffect(() => {
      if (inputRef && inputRef.current && autoFocus) {
        updateAutocompleteOptions(value)
        inputRef.current.focus()
      }
    }, [autoFocus, googleLoaded])

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
      setOptions([])

      return () => {
        setValue(initialValue)
        setOptions([])
      }
    }, [])

    const onInputChange = (inputValue, locationHasBeenSelected) => {
      setValue(inputValue)

      if (handleOnChange) {
        handleOnChange(inputValue)
      }

      if (locationHasBeenSelected) return

      updateAutocompleteOptions(inputValue)
    }

    const onInputValueChange = (
      inputValue,
      closeMenu,
      locationHasBeenSelected
    ) => {
      setShowEmptyState(false)
      setError(false)
      clearGeoCoordinatesError()

      if (!inputValue) {
        setNoMatches(false)
        setShowEmptyState(true)
        closeMenu()
      }

      onInputChange(inputValue, locationHasBeenSelected)
    }

    const handleClearInput = (reset) => {
      setValue('')
      setOptions([])
      reset()

      if (handleOnChange) {
        handleOnChange('')
      }

      if (handleOnSelect) {
        handleOnSelect('')
      }
    }

    const onInputFocus = (inputValue) => {
      if (!inputValue) {
        setShowEmptyState(true)
        setError(false)
        clearGeoCoordinatesError()
      } else {
        updateAutocompleteOptions(inputValue)
      }
    }

    const onInputBlur = (inputValue, reset) => {
      if (!inputValue) {
        handleClearInput(reset)
      }
      setShowEmptyState(false)
    }

    const onClearButtonClick = (reset) => {
      handleClearInput(reset)

      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    const {
      isOpen,
      openMenu,
      closeMenu,
      setInputValue,
      getLabelProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      reset,
    } = useCombobox({
      id: id || name,
      defaultHighlightedIndex: 0,
      items: options,
      itemToString: (item) => (item ? item.name : ''),
      onSelectedItemChange: ({ selectedItem, type }) => {
        const shouldSubmitSelectedItem =
          type === useCombobox.stateChangeTypes.InputKeyDownEnter ||
          type === useCombobox.stateChangeTypes.ItemClick

        if (shouldSubmitSelectedItem) {
          handleOnSubmit(selectedItem)

          if (inputRef.current) {
            inputRef.current.blur()
          }
        }

        if (!shouldSubmitSelectedItem && handleOnSelect) {
          handleOnSelect(selectedItem)

          if (selectedItem && inputRef.current) {
            inputRef.current.blur()
          }
        }
      },
      onInputValueChange: ({ inputValue: inputVal, selectedItem }) => {
        const locationHasBeenSelected = inputVal === selectedItem?.name
        onInputValueChange(inputVal, closeMenu, locationHasBeenSelected)
      },
    })

    useEffect(() => {
      setInputValue(initialValue)
    }, [])

    const showAutoCompleteSuggestions = options.length > 0

    const renderDropdownContent = (
      emptyState,
      noMatches,
      hasAutocompleteSuggestions,
      error,
      geoCoordinatesError,
      searchTerm
    ) => {
      if (error)
        return (
          <div className={styles.errorItem} data-testid="error-text">
            <span className={styles.errorHeading}>
              Oops! Something broke...
            </span>
            <span className={styles.errorText}>
              We couldn’t retrieve any locations.
            </span>
            <span className={styles.errorText}>Please try again later.</span>
          </div>
        )

      if (geoCoordinatesError)
        return (
          <div className={styles.errorItem} data-testid="error-text">
            <span className={styles.errorHeading}>
              Oops! Something broke...
            </span>
            <span className={styles.errorText}>
              We couldn’t retrieve the geographical coordinates for this
              location.
            </span>
            <span className={styles.errorText}>Please try again later.</span>
          </div>
        )

      if (emptyState && !searchTerm)
        return (
          <div className={styles.emptyItem} data-testid="empty-search-text">
            <span className={styles.emptyItemText}>
              You can search by{' '}
              <span className={styles.emptyItemBoldText}>university</span>,{' '}
              <span className={styles.emptyItemBoldText}>city</span>,{' '}
              <span className={styles.emptyItemBoldText}>area</span>,{' '}
              <span className={styles.emptyItemBoldText}>street name</span> or{' '}
              <span className={styles.emptyItemBoldText}>landmark</span>.
            </span>
          </div>
        )

      if (noMatches)
        return (
          <div className={styles.noMatchesItem} data-testid="no-matches-found">
            <span className={styles.noMatchesHeading}>No matches</span>
            <span className={styles.noMatchesText}>
              We can't find any matches for '{searchTerm}'.
            </span>
            <span className={styles.noMatchesText}>
              Maybe try a less specific location?
            </span>
          </div>
        )

      if (hasAutocompleteSuggestions)
        return (
          <ul className={styles.list}>
            {options.map((item, index) => (
              <li
                data-testid={`autocomplete-option-${index}`}
                className={classNames(styles.item, {
                  [styles.itemHighlighted]: highlightedIndex === index,
                })}
                key={item.placeId}
                {...getItemProps({ index })}
              >
                <span className={styles.iconWrapper}>{icons[item.type]}</span>

                <span className={styles.listItemText}>
                  {renderSuggestedItem(item.label, value)}
                </span>
              </li>
            ))}
          </ul>
        )
    }

    const showDropdown =
      (isOpen && options.length > 0) ||
      showEmptyState ||
      noMatches ||
      error ||
      (showGeoCoordinatesError && value)

    return (
      <div className={classNames(styles.field, className)}>
        {renderInputWrapper({
          children: (
            <>
              {renderInput({
                className: classNames({
                  [styles.invalidInput]: showGeoCoordinatesError,
                }),
                ...getInputProps({
                  ref: (el) => {
                    inputRef.current = el
                    if (ref) {
                      ref.current = el
                    }
                  },
                  name,
                  type: 'search',
                  role: 'searchbox',
                  value,
                  onBlur: (e) => {
                    onInputBlur(e.target.value, reset)
                  },
                  onFocus: (e) => {
                    onInputFocus(e.target.value)
                    if (!isOpen) {
                      openMenu()
                    }
                  },
                }),
              })}

              {renderLabel(getLabelProps())}

              {value &&
                renderClearButton({
                  onClick: () => {
                    onClearButtonClick(reset)
                  },
                  'aria-controls': getInputProps().id,
                })}
            </>
          ),
        })}

        <div
          {...getMenuProps()}
          className={classNames(styles.menu, {
            [styles.menuOpen]: showDropdown,
          })}
          aria-hidden={!showDropdown}
          data-testid="location-dropdown-menu"
        >
          {renderMenuContainer({
            children: renderDropdownContent(
              showEmptyState,
              noMatches,
              showAutoCompleteSuggestions,
              error,
              showGeoCoordinatesError,
              value
            ),
          })}
        </div>
      </div>
    )
  }
)

LocationAutocompleteField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnSelect: PropTypes.func,
  handleOnSubmit: PropTypes.func.isRequired,
  autocompleteApi: PropTypes.func.isRequired,
  countryCodeForAutocomplete: PropTypes.oneOf([
    'afsCountries',
    'gb',
    'ie',
    'au',
  ]).isRequired,
  autoFocus: PropTypes.bool,
  showGeoCoordinatesError: PropTypes.bool.isRequired,
  clearGeoCoordinatesError: PropTypes.func.isRequired,
  setCountry: PropTypes.func,
  renderInputWrapper: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  renderClearButton: PropTypes.func.isRequired,
  renderLabel: PropTypes.func.isRequired,
  renderMenuContainer: PropTypes.func.isRequired,
}

export default LocationAutocompleteField
