import xss from 'xss'
import toTitleCase from '../utils/formatting/toTitleCase'
import { mode } from '../constants/mode'
import { occupancy } from '../constants/occupancy'
import { propertyType } from '../constants/propertyType'
import { billTypes } from '../constants/billTypes'
import {
  defaultMinPrice,
  defaultMaxPrice,
} from './defaultSearchParameterValues'
import {
  countryCodeForPropertyAlerts,
  linkBaseFromCountryCode,
} from '../contexts/countryContext'

const replacePlusSignsWithSpacesIn = (location) => {
  return location.replace(/\+/g, ' ')
}

const parseQueryString = (queryString) => {
  const query = { propertyTypes: [], bills: [] }

  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')

  pairs.forEach((pair) => {
    const splitPair = pair.split('=')

    const queryPropertyName = decodeURIComponent(splitPair[0])
    const queryPropertyValue = xss(decodeURIComponent(splitPair[1]))

    if (queryPropertyName === 'propertyTypes') {
      query.propertyTypes.push(queryPropertyValue)
    } else if (queryPropertyName === 'bills') {
      query.bills.push(queryPropertyValue)
    } else {
      query[queryPropertyName] = queryPropertyValue || ''
    }

    if (query.searchType && query.searchType.toLowerCase() !== 'any') {
      query.propertyTypes = [query.searchType]
    }

    if (query.propertyType && query.propertyType.toLowerCase() !== 'any') {
      query.propertyTypes = [query.propertyType]
    }
  })

  if (query.instantBook) {
    return {
      ...query,
      instantBook: query.instantBook === 'true' || undefined,
    }
  }

  if (!query.location) return query

  return {
    ...query,
    location: replacePlusSignsWithSpacesIn(decodeURI(query.location)),
  }
}

export const propertyTypesParamsFrom = (propertyTypes) => {
  if (!propertyTypes) return ''
  if (propertyTypes.length === 0) return ''

  const typesAsQueryStringParams = propertyTypes
    .map((type) => `propertyTypes=${type}`)
    .join('&')

  return `&${typesAsQueryStringParams}`
}

export const billsParamsFrom = (bills) => {
  if (!bills) return ''
  if (bills.length === 0) return ''

  const billsAsQueryStringParams = bills
    .map((type) => `bills=${type}`)
    .join('&')

  return `&${billsAsQueryStringParams}`
}

export const occupancyTypeFromEntirePlace = (entirePlace) => {
  return entirePlace ? occupancy.wholeProperty : occupancy.min
}

const getPrice = (query) => {
  if (parseFloat(query.maxPrice) === defaultMaxPrice) return ''
  if (query.maxPrice) return query.maxPrice
  if (query.price) return query.price
  return ''
}

const getMinPrice = (query) => {
  const minPriceNumber = query.minPrice
    ? parseFloat(query.minPrice)
    : defaultMinPrice

  const maxPriceNumber = getMaxPrice(query)

  if (minPriceNumber > maxPriceNumber) {
    return defaultMinPrice
  }

  if (minPriceNumber < defaultMinPrice) {
    return defaultMinPrice
  }

  return minPriceNumber
}

const getMaxPrice = (query) => {
  const minPriceNumber = query.minPrice
    ? parseFloat(query.minPrice)
    : defaultMinPrice

  let maxPriceNumber = defaultMaxPrice

  if (query.maxPrice) {
    maxPriceNumber = parseFloat(query.maxPrice)
  }

  if (query.price) {
    maxPriceNumber = parseFloat(query.price)
  }

  if (minPriceNumber > maxPriceNumber) {
    return defaultMaxPrice
  }

  if (maxPriceNumber > defaultMaxPrice) {
    return defaultMaxPrice
  }

  return maxPriceNumber
}

const orderPropertyTypes = (selectedPropertyTypes) => {
  let orderedPropertyTypes = []

  if (selectedPropertyTypes.includes(propertyType.halls)) {
    orderedPropertyTypes.push(propertyType.halls)
  }

  if (selectedPropertyTypes.includes(propertyType.house)) {
    orderedPropertyTypes.push(propertyType.house)
  }

  if (selectedPropertyTypes.includes(propertyType.flat)) {
    orderedPropertyTypes.push(propertyType.flat)
  }

  if (selectedPropertyTypes.includes(propertyType.studio)) {
    orderedPropertyTypes.push(propertyType.studio)
  }

  return orderedPropertyTypes
}

const orderBills = (selectedBills) => {
  let orderedBills = []

  if (selectedBills.includes(billTypes.none)) {
    return [billTypes.none]
  }

  if (selectedBills.includes(billTypes.gas)) {
    orderedBills.push(billTypes.gas)
  }

  if (selectedBills.includes(billTypes.electricity)) {
    orderedBills.push(billTypes.electricity)
  }

  if (selectedBills.includes(billTypes.water)) {
    orderedBills.push(billTypes.water)
  }

  if (selectedBills.includes(billTypes.internet)) {
    orderedBills.push(billTypes.internet)
  }

  return orderedBills
}

const getMaxPriceForPropertyAlertCriteria = (criteria) => {
  if (criteria.maxPrice && castToNumber(criteria.maxPrice) === defaultMaxPrice)
    return 0
  if (criteria.maxPrice) return castToNumber(criteria.maxPrice)
  return castToNumber(criteria.price)
}

const propertyTypesWithHallsRemoved = (criteria) => {
  return criteria.propertyTypes.filter((t) => t !== propertyType.halls)
}

export const toSearchPageUrl = (criteria) => {
  const linkBase = linkBaseFromCountryCode(criteria.countryCode)

  const location = criteria.location || ''

  const latitudeAndLongitude = criteria.hasGeoCoordinates
    ? `&latitude=${criteria.latitude}&longitude=${criteria.longitude}`
    : ''

  const radius = criteria.radius ? `&radius=${criteria.radius}` : ''

  const instantBook = criteria.instantBook ? '&instantBook=true' : ''

  const propertyTypes = propertyTypesParamsFrom(criteria.propertyTypes)

  const bills = billsParamsFrom(criteria.bills)

  const lettingPeriod = criteria.lettingPeriod
    ? `&lettingPeriod=${criteria.lettingPeriod}`
    : ''

  const moveInFrom = criteria.moveInFrom
    ? `&moveInFrom=${criteria.moveInFrom}`
    : ''

  const moveInTo = criteria.moveInTo ? `&moveInTo=${criteria.moveInTo}` : ''

  const minContractLength = criteria.minContractLength
    ? `&minContractLength=${criteria.minContractLength}`
    : ''

  const maxContractLength = criteria.maxContractLength
    ? `&maxContractLength=${criteria.maxContractLength}`
    : ''

  const sorting =
    criteria.sortBy && criteria.order
      ? `&sortBy=${criteria.sortBy}&order=${criteria.order}`
      : ''

  return `${linkBase}/search-results?location=${encodeURI(location)}&beds=${
    criteria.beds
  }&occupancy=${
    criteria.occupancy
  }${instantBook}${propertyTypes}${bills}&minPrice=${
    criteria.minPrice
  }&maxPrice=${
    criteria.maxPrice
  }${lettingPeriod}${latitudeAndLongitude}${radius}&geo=${
    criteria.geo
  }${moveInFrom}${moveInTo}${minContractLength}${maxContractLength}${sorting}&page=${
    criteria.page
  }`
}

export const toLandingPageUrl = (criteria) => {
  const { locationPath, areaPath, propertyType } = criteria
  const area = areaPath ? `/${areaPath}` : ''

  let property

  switch (propertyType) {
    case 'halls':
      property = '/student-halls'
      break
    case 'house':
      property = '/student-houses'
      break
    case 'flat':
      property = '/student-flats'
      break
    case 'studio':
      property = '/studios'
      break
    default:
      property = ''
  }

  return `/${locationPath}${area}${property}`
}

export const toSearchServiceCriteria = (criteria) => {
  return {
    ...criteria,
    location: criteria.locationForSearch,
    numberOfBedrooms: criteria.beds,
    maxPrice:
      criteria.maxPrice >= defaultMaxPrice ? undefined : criteria.maxPrice,
    mode: criteria.geo ? mode.geo : mode.text,
  }
}

const buildCriteria = (query, afsLocations, countryCode) => {
  let criteria = {}

  const locationIsDefault = (location) => {
    if (!location) return true
    if (location === 'any') return true
    return false
  }

  const shouldPerformGeoSearchFor = (selectedLocation) => {
    if (locationIsDefault(selectedLocation)) return false

    return !afsLocations.find((location) =>
      location.possibleNames.includes(selectedLocation)
    )
  }

  const getGeoCoordinatesOfAfsLocation = (selectedLocation) => {
    const location = afsLocations.find((location) =>
      location.possibleNames.includes(selectedLocation)
    )

    return {
      latitude: location.latitude,
      longitude: location.longitude,
    }
  }

  const withLocation = (selectedLocation, geoCoordinates = {}) => {
    if (!selectedLocation) {
      criteria = {
        ...criteria,
        location: '',
        locationForSearch: '',
        latitude: '',
        longitude: '',
        radius: '',
        hasGeoCoordinates: false,
        geo: false,
        page: 1,
      }

      return criteria
    }

    if (shouldPerformGeoSearchFor(selectedLocation)) {
      criteria = {
        ...criteria,
        location: selectedLocation,
        locationForSearch: getLocationForSearch(selectedLocation),
        latitude: geoCoordinates.latitude,
        longitude: geoCoordinates.longitude,
        radius: geoCoordinates.radius,
        hasGeoCoordinates: true,
        geo: true,
        page: 1,
      }

      return criteria
    }

    const { latitude, longitude } =
      getGeoCoordinatesOfAfsLocation(selectedLocation)

    criteria = {
      ...criteria,
      location: selectedLocation,
      locationForSearch: getLocationForSearch(selectedLocation),
      latitude,
      longitude,
      radius: '',
      hasGeoCoordinates: true,
      geo: false,
      page: 1,
    }

    return criteria
  }

  const withCountryCode = (selectedCountryCode) => {
    criteria = {
      ...criteria,
      countryCode: selectedCountryCode,
    }

    return criteria
  }

  const withInstantBook = (selectedInstantBook) => {
    criteria = {
      ...criteria,
      instantBook: selectedInstantBook || undefined,
    }

    return criteria
  }

  const withPropertyTypes = (selectedPropertyTypes) => {
    criteria = {
      ...criteria,
      propertyTypes: orderPropertyTypes(selectedPropertyTypes),
      page: 1,
    }

    return criteria
  }

  const withBills = (selectedBills) => {
    criteria = {
      ...criteria,
      bills: orderBills(selectedBills),
      page: 1,
    }

    return criteria
  }

  const withMinPrice = (selectedMinPrice) => {
    criteria = {
      ...criteria,
      minPrice: selectedMinPrice,
      page: 1,
    }

    return criteria
  }

  const withMaxPrice = (selectedMaxPrice) => {
    criteria = {
      ...criteria,
      maxPrice: selectedMaxPrice,
      page: 1,
    }

    return criteria
  }

  const withLettingPeriod = (selectedLettingPeriod) => {
    criteria = {
      ...criteria,
      lettingPeriod: selectedLettingPeriod,
      page: 1,
    }

    return criteria
  }

  const incrementBedsByOne = () => {
    criteria = {
      ...criteria,
      beds: criteria.beds + 1,
      page: 1,
    }

    return criteria
  }

  const decrementBedsByOne = () => {
    criteria = {
      ...criteria,
      beds: criteria.beds - 1,
      page: 1,
    }

    return criteria
  }

  const withBeds = (selectedBeds) => {
    criteria = {
      ...criteria,
      beds: selectedBeds,
      page: 1,
    }

    return criteria
  }

  const withOccupancy = (selectedOccupancy) => {
    criteria = {
      ...criteria,
      occupancy: selectedOccupancy,
      page: 1,
    }

    return criteria
  }

  const withMoveInDates = (selectedMoveInDates) => {
    criteria = {
      ...criteria,
      ...selectedMoveInDates,
      page: 1,
    }

    return criteria
  }

  const withContractLength = (selectedContractLength) => {
    criteria = {
      ...criteria,
      ...selectedContractLength,
      page: 1,
    }

    return criteria
  }

  const withFilters = (filters) => {
    if (filters.propertyTypes) {
      filters.propertyTypes = orderPropertyTypes(filters.propertyTypes)
    }

    if (filters.bills) {
      filters.bills = orderBills(filters.bills)
    }

    criteria = {
      ...criteria,
      ...filters,
      page: 1,
    }

    return criteria
  }

  const withPage = (selectedPage) => {
    criteria = {
      ...criteria,
      page: selectedPage,
    }

    return criteria
  }

  const sortByPriceAscending = () => {
    criteria = {
      ...criteria,
      sortBy: 'price',
      order: 'asc',
      page: 1,
    }

    return criteria
  }

  const sortByPriceDescending = () => {
    criteria = {
      ...criteria,
      sortBy: 'price',
      order: 'desc',
      page: 1,
    }

    return criteria
  }

  const sortByDefault = () => {
    criteria = {
      ...criteria,
      sortBy: '',
      order: '',
      page: 1,
    }

    return criteria
  }

  const getLocation = (location) => {
    if (locationIsDefault(location)) {
      return ''
    }

    return location
  }

  const getLocationForSearch = (location) => {
    const locationWithAnAlias = afsLocations.find((loc) =>
      loc.possibleNames.includes(location)
    )

    if (locationWithAnAlias) {
      return locationWithAnAlias.name
    }

    return !location || location === 'any' ? '' : location
  }

  criteria = {
    ...query,
    countryCode: countryCode || 'gb',
    location: getLocation(query.location),
    locationForSearch: getLocationForSearch(query.location),
    beds: castToNumber(query.beds),
    occupancy: query.occupancy || occupancy.min,
    instantBook: query.instantBook || undefined,
    minContractLength: query.minContractLength
      ? Number(query.minContractLength)
      : null,
    maxContractLength: query.maxContractLength
      ? Number(query.maxContractLength)
      : null,
    propertyTypes: !query.propertyTypes
      ? []
      : orderPropertyTypes(query.propertyTypes),
    bills: !query.bills ? [] : orderBills(query.bills),
    price: getPrice(query),
    minPrice: getMinPrice(query),
    maxPrice: getMaxPrice(query),
    lettingPeriod: query.lettingPeriod || '',
    latitude: query.latitude ? parseFloat(query.latitude) : '',
    longitude: query.longitude ? parseFloat(query.longitude) : '',
    radius: query.radius ? parseFloat(query.radius) : '',
    geo: shouldPerformGeoSearchFor(query.location),
    sortBy: query.sortBy || '',
    order: query.order || '',
    page: query.page ? parseFloat(query.page) : 1,
    hasGeoCoordinates:
      query.latitude !== undefined &&
      query.latitude !== '' &&
      query.longitude !== undefined &&
      query.longitude !== '',
    moveInFrom: query.moveInFrom || '',
    moveInTo: query.moveInTo || '',
    afsLocations,
    withLocation,
    withCountryCode,
    withInstantBook,
    withPropertyTypes,
    withBills,
    withMinPrice,
    withMaxPrice,
    withLettingPeriod,
    withBeds,
    incrementBedsByOne,
    decrementBedsByOne,
    withOccupancy,
    withMoveInDates,
    withContractLength,
    withFilters,
    withPage,
    sortByPriceAscending,
    sortByPriceDescending,
    sortByDefault,
  }

  return criteria
}

export const parseSearchCriteriaFrom = (
  queryString,
  afsLocations,
  countryCode
) => {
  const parsed = parseQueryString(queryString)
  return buildCriteria(parsed, afsLocations, countryCode)
}

export const getSearchCriteriaFrom = (
  queryObject,
  afsLocations,
  countryCode
) => {
  return buildCriteria(queryObject, afsLocations, countryCode)
}

const castToNumber = (param) => (isNaN(Number(param)) ? 0 : Number(param))

const propertyTypesToTitleCase = (propertyTypes) => {
  if (propertyTypes) {
    return propertyTypes.map((propertyType) => toTitleCase(propertyType))
  }

  return []
}

const buildPropertyAlertCriteria = (criteria, currency) => {
  const alertCriteria = {
    location:
      !criteria.location || criteria.location === 'any'
        ? ''
        : criteria.location,
    numberOfBedrooms: castToNumber(criteria.beds),
    occupancy: criteria.occupancy || 'min',
    minPrice: {
      amount: castToNumber(criteria.minPrice),
      currency,
    },
    maxPrice: {
      amount: getMaxPriceForPropertyAlertCriteria(criteria),
      currency,
    },
    lettingPeriod: '',
    propertyTypes: criteria.propertyTypes
      ? propertyTypesToTitleCase(
          orderPropertyTypes(propertyTypesWithHallsRemoved(criteria))
        )
      : [],
    coordinates: {
      latitude: castToNumber(criteria.latitude),
      longitude: castToNumber(criteria.longitude),
    },
    radius: castToNumber(criteria.radius),
    geo: criteria.geo === true || criteria.geo === 'true',
  }

  return alertCriteria
}

export const parsePropertyAlertCriteriaFrom = (queryString, currency) => {
  const parsed = !!queryString ? parseQueryString(queryString) : {}

  return buildPropertyAlertCriteria(parsed, currency)
}

export const getPropertyAlertCriteriaFrom = (searchCriteria, currency) => {
  return buildPropertyAlertCriteria(searchCriteria, currency)
}

export const getSearchCriteriaFromPropertyAlertCriteria = (
  propertyAlertCriteria,
  afsLocations
) => {
  const validatedCoordinates = () => {
    const { latitude, longitude } = propertyAlertCriteria.coordinates

    if (latitude === 0 && longitude === 0) {
      return {
        latitude: '',
        longitude: '',
      }
    }

    return {
      latitude,
      longitude,
    }
  }

  const searchQueryFromAlertCriteria = {
    ...propertyAlertCriteria,
    beds: propertyAlertCriteria.numberOfBedrooms,
    minPrice: propertyAlertCriteria.minPrice.amount,
    maxPrice: propertyAlertCriteria.maxPrice.amount,
    latitude: validatedCoordinates().latitude,
    longitude: validatedCoordinates().longitude,
    propertyTypes: propertyAlertCriteria.propertyTypes.map((type) =>
      type.toLowerCase()
    ),
  }

  return buildCriteria(
    searchQueryFromAlertCriteria,
    afsLocations,
    countryCodeForPropertyAlerts
  )
}
